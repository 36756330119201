type jobList = {
  employmentType: string;
  role: string;
  href: string;
};
export default function Recruit() {
  const posts: jobList[] = [];
  return (
    <div className="px-4 sm:px-6 lg:px-8 w-[90%] md:w-[60%] m-auto mt-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900 text-center">
            募集ポジション
          </h1>
          <p className="text-center mt-5">
            SIGQでは現在以下のポジションを募集しております。
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {posts.length === 0 && (
                  <div className="mt-5">現在は募集を行っておりません。</div>
                )}
                {posts.length > 0 &&
                  posts.map((post, i) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap px-3 py-5 text-gray-500">
                        <td className="whitespace-nowrap px-3  text-gray-500">
                          <a href={post.href}>
                            <div className="text-gray-500">
                              {post.employmentType}
                            </div>
                            <div className="mt-1 text-gray-900">
                              {post.role}
                            </div>
                          </a>
                        </td>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
