import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";

export default function NoAuthLayout() {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Header />
        <div
          className="flex-column flex-grow"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div>
            <Suspense fallback={<div></div>}>
              <Outlet />
            </Suspense>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
