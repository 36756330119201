export default function Announce() {
  const posts = [
    {
      title: "本社移転および株式会社化のお知らせ",
      href: "/announce/20241115",
      date: "2024年11月15日",
    },
  ];
  return (
    <div className="px-4 sm:px-6 lg:px-8 w-[90%] md:w-[60%] m-auto mt-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900 text-center">
            お知らせ
          </h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {posts.map((post) => (
                  <tr key={post.title}>
                    <td className="whitespace-nowrap px-3 py-5 text-gray-500">
                      <td className="whitespace-nowrap px-3  text-gray-500">
                        <a href={post.href}>
                          <div className="text-gray-500">{post.date}</div>
                          <div className="mt-1 text-gray-900">{post.title}</div>
                        </a>
                      </td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
