import { lazy, Suspense } from "react";

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Announce from "./pages/Announce";
import Announce20241115 from "./pages/accounces/20241115";
import Recruit from "./pages/Recruit";

const LP = lazy(() => import("./pages/LP"));
const PublicNotice = lazy(() => import("./pages/PublicNotice"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="" element={<LP />} />
            <Route path="/public-notice" element={<PublicNotice />} />

            <Route path="/announce" element={<Announce />} />
            <Route path="/announce/20241115" element={<Announce20241115 />} />

            <Route path="/recruit" element={<Recruit />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
